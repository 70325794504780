import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button } from "mdbreact";
import "./nav.css";
import Logo from "../../img/logo-groupe-phode.png";
import IconDashboard from "../../img/icon-dashboard.png";
import IconDocuments from "../../img/icon-documents.png";
import IconMedias from "../../img/icon-medias.png";
import IconNews from "../../img/icon-news.png";
import IconUsers from "../../img/icon-users.png";
import IconLogo from "../../img/icon-phode.png";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");

class Nav extends React.Component {
  state = {
    isLoading: true,
    translationsGlobal: "",
  };

  render() {
    if (this.state.isLoading === false) {
      return (
        <div
          className={
            "pr-0 " + this.props.contentLeft + " " + this.props.burgerState
          }
          id="nav"
        >
          <nav>
            <div id="logo">
              <Link to="/dashboard">
                <img src={Logo} className="logo-full" alt="logo" />
                <img src={IconLogo} className="logo-icon" alt="logo" />
              </Link>
            </div>
            <ul className="d-flex flex-column p-0">
              <li className="nav-link">
                <Link to="/dashboard">
                  <Button color="phode" className="d-flex align-items-center">
                    <img
                      src={IconDashboard}
                      className="nav-icon"
                      alt="dashboard"
                    />
                    <div>
                      {this.state.translationsGlobal.dashboard[currentLanguage]}
                    </div>
                  </Button>
                </Link>
              </li>
              <li className="nav-link">
                <Link to="/news">
                  <Button color="phode" className="d-flex align-items-center">
                    <img src={IconNews} className="nav-icon" alt="news" />
                    <div>
                      {this.state.translationsGlobal.news[currentLanguage]}
                    </div>
                  </Button>
                </Link>
              </li>
              <li className="nav-link">
                <Link to="/documents">
                  <Button color="phode" className="d-flex align-items-center">
                    <img
                      src={IconDocuments}
                      className="nav-icon"
                      alt="documents"
                    />
                    <div>
                      {this.state.translationsGlobal.documents[currentLanguage]}
                    </div>
                  </Button>
                </Link>
              </li>
              <li className="nav-link">
                <Link to="/medias">
                  <Button color="phode" className="d-flex align-items-center">
                    <img src={IconMedias} className="nav-icon" alt="medias" />
                    <div>
                      {this.state.translationsGlobal.medias[currentLanguage]}
                    </div>
                  </Button>
                </Link>
              </li>
              {Cookies.get("role") === "admin" ? (
                <Fragment>
                  <li className="nav-link">
                    <Link to="/users">
                      <Button
                        color="phode"
                        className="d-flex align-items-center"
                      >
                        <img src={IconUsers} className="nav-icon" alt="users" />
                        <div>
                          {this.state.translationsGlobal.users[currentLanguage]}
                        </div>
                      </Button>
                    </Link>
                  </li>
                  <li className="nav-link">
                    <Link to="/filters">
                      <Button
                        color="phode"
                        className="d-flex align-items-center"
                      >
                        <img
                          src={IconDocuments}
                          className="nav-icon"
                          alt="filters"
                        />
                        <div>
                          {
                            this.state.translationsGlobal.filters[
                              currentLanguage
                            ]
                          }
                        </div>
                      </Button>
                    </Link>
                  </li>
                </Fragment>
              ) : null}
            </ul>
          </nav>
          <style jsx>{`
            #nav {
              -webkit-transition: all 0.5s;
              -moz-transition: all 0.5s;
              transition: all 0.5s;
            }
            #nav.burgerToOpen .nav-link div {
              display: none;
            }
            #nav.burgerToOpen .nav-link img {
              margin: auto;
            }
            #nav.burgerToOpen .logo-full {
              display: none;
            }
            #nav.burgerToOpen #logo {
              text-align: center;
            }
            #nav.burgerToOpen .logo-icon {
              width: 45px;
            }
            #nav.burgerToClose .logo-icon {
              display: none;
            }
            .nav-icon {
            }
            nav {
              position: relative;
            }
            .nav-link {
              padding: 0;
            }
            .nav-link img {
              margin-right: 10px;
            }
            .nav-link button {
              padding: 10px 15px;
            }
            .burgerToClose .fa-bars {
              display: none;
            }
            .burgerToOpen .fa-close {
              display: none;
            }

            @media only screen and (max-width: 767px) {
              #logo {
                z-index: 10000;
              }
              #logo img {
                height: 50px;
                width: auto;
              }
              #nav {
                padding: 0;
                position: relative;
                z-index: 1 !important;
              }
              #nav ul {
                flex-direction: row !important;
                justify-content: center;
                margin: 0;
              }
              #nav button div {
                display: none;
              }
              #nav.burgerToOpen nav {
                display: none;
              }
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .all([axios.get(process.env.REACT_APP_API_URL + "translations/global")])
      .then(
        axios.spread((dataTrGlobal) => {
          this.setState({
            isLoading: false,
            translationsGlobal: dataTrGlobal.data[0].translations,
          });
        })
      )
      .catch(function (error) {
        console.log(error);
      });
  }
}

export default Nav;
