import axios from "axios";
import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import { Button, Navbar, NavbarNav, Collapse, NavItem } from "mdbreact";

import Dashboard from "../../dashboard/Dashboard";
import Documents from "../../documents/Documents";
import DocumentEdit from "../../documents/DocumentEdit";
import DocumentCreate from "../../documents/DocumentCreate";
import Legal from "../../Legal";
import Medias from "../../medias/Medias";
import News from "../../news/News";

// Update
import NewEdit from "../../../containers/update/News";

// Update (edit / create)
//import updateDocuments from "../../../containers/update/Documents";
import updateMedias from "../../../containers/update/Medias";
import updateNews from "../../../containers/update/News";
import updateUsers from "../../../containers/update/Users";
import Filters from "../../../containers/Filters";

//
import Users from "../../users/Users";
import UserView from "../../users/UserView";
import UserEdit from "../../users/UserEdit";
import "./main.css";
import Cookies from "js-cookie";
import Select from "react-select";
import UserListEdit from "../../users/UserListEdit";
import Create from "../../../containers/Create";
import View from "../../../containers/View";
import Edit from "../../../containers/Edit";
const currentLanguage = Cookies.get("lang");

const langOptions = [
  { value: "en", label: "EN" },
  { value: "fr", label: "FR" },
  { value: "es", label: "ES" },
];

class Main extends React.Component {
  state = {
    isLoading: true,
    firstName: "",
    lastName: "",
    selectedOption: null,
    translationsGlobal: {
      logout: "",
      myAccount: "",
    },
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    window.location.replace("/" + selectedOption.url);
    if (selectedOption.value === "logout") {
      localStorage.removeItem("extranet_phode_id");
      Cookies.remove("username");
      Cookies.remove("role");
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("lang");
      Cookies.remove("companyAddress");
      Cookies.remove("companyName");
      Cookies.remove("companyLogo");
      Cookies.remove("companyPhone");
      window.location.href = "/";
    } else if (selectedOption.value === "user") {
      window.location.href =
        "/users/" + localStorage.getItem("extranet_phode_id") + "/view";
    }
  };

  back() {
    window.history.back();
  }

  langChange = (lang) => {
    Cookies.set("lang", lang.label);
    this.setState(
      {
        activeLanguage: {
          value: lang.value,
          label: lang.label,
        },
      },
      () => {
        window.location.replace(window.location.href);
      }
    );
  };

  render() {
    if (this.state.isLoading === false) {
      const { selectedOption } = this.state;
      const navOptions = [
        {
          value: "user",
          label: this.state.translationsGlobal.myAccount[currentLanguage],
        },
        {
          value: "logout",
          label: this.state.translationsGlobal.logout[currentLanguage],
        },
      ];
      return (
        <Fragment>
          <Navbar expand="md" id="navTop">
            <Button
              id="burger"
              className={this.props.burgerState}
              onClick={this.props.burgerClick}
            >
              <i className="fa fa-close" aria-hidden="true" />
              <i className="fa fa-bars" aria-hidden="true" />
            </Button>
            {window.location.href.indexOf("dashboard") !== -1 ? null : (
              <div className="cursor-pointer" id="back" onClick={this.back}>
                <i className="fa fa-arrow-left" aria-hidden="true" />
              </div>
            )}
            <Collapse navbar>
              <NavbarNav right>
                <NavItem className="align-items-center d-flex mr-3">
                  <Select
                    id="userMenu"
                    value={selectedOption}
                    placeholder={
                      this.state.translationsGlobal.hello[currentLanguage] +
                      " " +
                      this.state.firstName +
                      " " +
                      this.state.lastName
                    }
                    options={navOptions}
                    onChange={this.handleChange}
                    styles={{
                      option: (
                        styles,
                        { isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          backgroundColor: isDisabled ? "" : "white",
                        };
                      },
                    }}
                  />
                  {Cookies.get("role") !== "distributor" ? (
                    <Select
                      className="langSelect"
                      value={this.state.activeLanguage}
                      onChange={this.langChange}
                      options={langOptions}
                    />
                  ) : null}
                </NavItem>
              </NavbarNav>
            </Collapse>
          </Navbar>
          <main>
            {/* LOGIN */}

            {/*  */}
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/documents" component={Documents} />
            <Route exact path="/medias" component={Medias} />
            <Route exact path="/news" component={News} />
            <Route exact path="/filters" component={Filters} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/legal" component={Legal} />
            <Route path="/documents/edit/:id" component={DocumentEdit} />

            {/* CREATE */}
            <Route exact path="/documents/create" component={DocumentCreate} />
            <Route path="/create/:type" component={Create} />

            {/* EDIT */}
            <Route path="/edit/:type/:id" component={Edit} />
            <Route path="/update/medias/:id" component={updateMedias} />
            <Route path="/update/news/:id" component={updateNews} />
            <Route path="/update/users/:id" component={updateUsers} />

            {/* VIEW */}
            <Route path="/view/:type/:id" component={View} />

            <Route path="/news/:id/edit" component={NewEdit} />
            <Route path="/users/:id/view" component={UserView} />
            <Route path="/users/:id/edit" component={UserEdit} />
            <Route path="/userslist/:id/edit" component={UserListEdit} />
          </main>
          <style jsx>{`
            #back i {
              color: var(--green);
              font-size: 30px;
            }
            #burger {
              background: none !important;
              box-shadow: none !important;
            }
            #burger .fa {
              font-size: 25px;
            }
            .langSelect {
              position: relative;
            }
            .langSelect > div {
              background: none;
              border: 0;
              color: white;
              width: 75px;
            }
            .langSelect > div > div:first-child div {
              background: #232957;
              color: white;
            }
            .langSelect .css-2o5izw + div div {
              color: white !important;
            }
            #navTop {
              position: relative;
              z-index: 1000000000;
            }
            #navTop #userMenu {
              width: 200px;
            }
            .css-15k3avv {
              background: white !important;
            }
            #navTop #userMenu > div {
              background: transparent;
            }
            #navTop #userMenu div {
              border: 0;
            }
            .css-1492t68 {
              color: white;
            }
            .css-d8oujb {
              display: none;
            }
            .css-10nd86i {
              border-bottom: 0 !important;
            }
            .page #mainHeader {
              height: 150px;
            }
            .page h1 {
              margin-top: 30px;
            }

            @media only screen and (max-width: 767px) {
              #navTop {
                background: var(--blue);
                justify-content: flex-end;
                position: relative;
                top: 0;
              }
              #navTop button {
                padding-right: 10px;
              }
              #back + div {
                display: block;
              }
              #back {
                position: absolute;
                top: -125px;
              }
              #burger {
                display: none;
              }
              #burger + div {
                display: block;
              }
              #burger + div li {
                justify-content: center;
                margin: 0 !important;
              }
            }
          `}</style>
        </Fragment>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "rgpd/check/" +
            localStorage.getItem("extranet_phode_id")
        ),
        axios.get(process.env.REACT_APP_API_URL + "translations/users"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global"),
      ])
      .then(
        axios.spread((response, translationsUsers, translationsGlobal) => {
          this.setState({
            isLoading: false,
            ...response.data,
            translationsUsers: translationsUsers.data[0].translations,
            translationsGlobal: translationsGlobal.data[0].translations,
          });
        })
      )
      .catch(function (error) {
        console.log(error);
      });

    if (currentLanguage !== undefined) {
      this.setState({
        activeLanguage: {
          value: Cookies.get("lang").toLocaleLowerCase,
          label: Cookies.get("lang"),
        },
      });
    }
  }
}

export default Main;
