import React, { Fragment } from "react";

const DocumentType = (props) => {
  let type = props.type;
  switch (type) {
    case "pdf":
      type = type.replace("pdf", "file-pdf-o");
      break;
    default:
      break;
  }
  return (
    <Fragment>
      <i class={"fa white-text p-3 fa-" + type} />
      <style jsx>{`
        i {
          background: var(--blue);
          border-radius: 100%;
          font-size: 15px;
        }
      `}</style>
    </Fragment>
  );
};

export default DocumentType;
