import Dropzone from "react-dropzone";
import React from "react";
import { Button, Card, CardBody, Container, Input } from "mdbreact";
import MainHeader from "../core/main/MainHeader";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");

class UserEdit extends React.Component {
  state = {
    isLoading: true,
    userLogged: "demo", // Remplacer dynamiquement
    message: "",
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState(updatedState);
  };

  onDrop = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          upload: {
            uploadData: fileAsBinaryString,
            uploadName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  avatar = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          avatar: {
            avatarData: fileAsBinaryString,
            avatarName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  companyLogo = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          companyLogo: {
            companyLogoData: fileAsBinaryString,
            companyLogoName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    axios
      .post(
        process.env.REACT_APP_API_URL + "update/users/" + this.state._id,
        {
          ...this.state,
          ...this.state.avatar,
          ...this.state.companyLogo,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // handle success
        this.setState({
          message: response.data.message + " !",
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    Cookies.set("companyAddress", this.state.companyAddress);
    Cookies.set("companyName", this.state.companyName);
    Cookies.set("companyLogo", this.state.companyLogo);
    Cookies.set("companyPhone", this.state.companyPhone);
  };

  render() {
    if (this.state.isLoading === false) {
      return (
        <div id="user" className="page">
          <MainHeader
            title={this.state.firstName + " " + this.state.lastName}
          />
          <Container className="ml-0">
            <div id="userMenu" className="pt-3 mb-1" />
            <div id="user-content" className="pb-5">
              <Card>
                <CardBody>
                  <div id="edit-menu" className="d-flex mb-4">
                    <div className="mr-3">
                      <Link
                        to={
                          "/users/" +
                          localStorage.getItem("extranet_phode_id") +
                          "/view"
                        }
                      >
                        {this.state.translationsGlobal.see[currentLanguage]}
                      </Link>
                    </div>
                    <div>
                      <Link
                        to={"/users" + this.state.username + "/edit"}
                        className="active"
                      >
                        {this.state.translationsGlobal.edit[currentLanguage]}
                      </Link>
                    </div>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <h2 className="mb-3">
                      {this.state.translationsUsers.infoPerso[currentLanguage]}
                    </h2>
                    <table>
                      <tr>
                        <td>
                          {this.state.translationsUsers.avatar[currentLanguage]}
                          <br />
                          <div className="extension">*Format .JPG</div>
                        </td>
                        <td>
                          <Dropzone
                            onDrop={this.avatar.bind(this)}
                            multiple="false"
                          >
                            {
                              this.state.translationsGlobal.fileDrop[
                                currentLanguage
                              ]
                            }
                          </Dropzone>
                          {this.state.avatar ? (
                            <p>{this.state.avatar.avatarName}</p>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {
                            this.state.translationsUsers.firstname[
                              currentLanguage
                            ]
                          }
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                            id="firstName"
                            name="firstName"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {
                            this.state.translationsUsers.lastname[
                              currentLanguage
                            ]
                          }
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            id="lastName"
                            name="lastName"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {
                            this.state.translationsUsers.position[
                              currentLanguage
                            ]
                          }
                        </td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.profession}
                            onChange={this.handleChange}
                            id="profession"
                            name="profession"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {this.state.translationsUsers.phone[currentLanguage]}
                        </td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.phoneOne}
                            onChange={this.handleChange}
                            id="phoneOne"
                            name="phoneOne"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {this.state.translationsUsers.cell[currentLanguage]}
                        </td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.phoneTwo}
                            onChange={this.handleChange}
                            id="phoneTwo"
                            name="phoneTwo"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {this.state.translationsUsers.email[currentLanguage]}
                        </td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.email}
                            onChange={this.handleChange}
                            id="email"
                            name="email"
                          />
                        </td>
                      </tr>
                    </table>
                    <h2 className="mt-3">
                      {this.state.translationsUsers.infoPro[currentLanguage]}
                    </h2>
                    <p className="mb-3">
                      {this.state.translationsUsers.customize[currentLanguage]}
                    </p>
                    <table>
                      <tr>
                        <td>
                          {
                            this.state.translationsUsers.companyName[
                              currentLanguage
                            ]
                          }
                        </td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.companyName}
                            onChange={this.handleChange}
                            id="companyName"
                            name="companyName"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {
                            this.state.translationsUsers.address[
                              currentLanguage
                            ]
                          }
                        </td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.companyAddress}
                            onChange={this.handleChange}
                            id="companyAddress"
                            name="companyAddress"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {this.state.translationsUsers.phone[currentLanguage]}
                        </td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.companyPhone}
                            onChange={this.handleChange}
                            id="companyPhone"
                            name="companyPhone"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {
                            this.state.translationsUsers.country[
                              currentLanguage
                            ]
                          }
                        </td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.companyCountry}
                            onChange={this.handleChange}
                            id="companyCountry"
                            name="companyCountry"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {this.state.translationsUsers.logo[currentLanguage]}
                        </td>
                        <td>
                          <Dropzone
                            onDrop={this.companyLogo.bind(this)}
                            multiple="false"
                          >
                            {
                              this.state.translationsGlobal.fileDrop[
                                currentLanguage
                              ]
                            }
                          </Dropzone>
                          {this.state.companyLogo ? (
                            <p>{this.state.companyLogo.companyLogoName}</p>
                          ) : null}
                        </td>
                      </tr>
                    </table>
                    <div id="buttons" className="mt-5">
                      <Button color="phode-green" type="submit">
                        {this.state.translationsGlobal.save[currentLanguage]}
                      </Button>
                      <Link to={"/users/" + this.state.username + "/view"}>
                        <Button color="grey">
                          {
                            this.state.translationsGlobal.cancel[
                              currentLanguage
                            ]
                          }
                        </Button>
                      </Link>
                    </div>
                  </form>
                  <div id="messages">{this.state.message}</div>
                </CardBody>
              </Card>
            </div>
          </Container>
          <style jsx>{`
            .extension {
              color: var(--green);
            }
            #messages {
              color: var(--green);
              padding: 20px 0;
            }
            #edit-menu a:after {
              content: "";
              display: block;
              height: 5px;
              width: 100%;
            }
            #edit-menu a.active {
              color: var(--green);
            }
            #edit-menu a.active:after {
              background: var(--green);
            }
            #edit-menu a {
              color: var(--grayText);
            }
            #edit-menu a:after {
              background: var(--grayText);
            }
            h2 {
              font-weight: bold;
            }
            #userMenu {
              border-bottom: 1px solid #f0f0f0;
              padding: 10px 0;
            }
            #userMenu a {
              color: black;
            }
            #userMenu li {
              margin-right: 15px;
            }
            table {
              width: 100%;
            }
            table td {
              border: 1px solid #f0f0f0;
              padding: 10px 20px;
            }
            table > tr > td:first-child {
              width: 20%;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "view/users/" +
            localStorage.getItem("extranet_phode_id")
        ),
        axios.get(process.env.REACT_APP_API_URL + "translations/users"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global"),
      ])
      .then(
        axios.spread((dataItem, translationsUsers, translationsGlobal) => {
          this.setState({
            isLoading: false,
            ...dataItem.data,
            companyLogo: {
              companyLogoName: dataItem.data.companyLogo,
            },
            avatar: {
              avatarName: dataItem.data.avatar,
            },
            translationsUsers: translationsUsers.data[0].translations,
            translationsGlobal: translationsGlobal.data[0].translations,
          });
        })
      );
  }
}

export default UserEdit;
