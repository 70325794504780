import React from "react";
import { Button, Card, CardBody, Input } from "mdbreact";
import axios from "axios";

class Login extends React.Component {
  state = {
    isLoading: true,
    message: "",
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState(updatedState);
  };

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    if (this.state.password && this.state.password2 === this.state.password) {
      axios
        .post(process.env.REACT_APP_API_URL + "users/password/create", {
          _id: this.props.match.params.id,
          password: this.state.password,
        })
        .then(function (response) {
          alert(response.data.message);
          window.location.href = "/";
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("The two passwords doesn't match");
    }
  };

  render() {
    if (this.state.isLoading === false) {
      return (
        <div
          id="front"
          className="align-items-center d-flex justify-content-center"
        >
          <form
            className="p-4 text-center text-white"
            onSubmit={this.handleSubmit}
          >
            <Card>
              <CardBody>
                <img src="/img/logo-slogan.png" alt="logo" />
                <p className="mt-5 text-center">Define your new password</p>
                <Input
                  label="Password"
                  type="password"
                  id="password"
                  name="password"
                  onChange={this.handleChange}
                  value={this.state.password}
                />
                <Input
                  label="Retype Password"
                  type="password"
                  id="password2"
                  name="password2"
                  onChange={this.handleChange}
                  value={this.state.password2}
                />
                <div className="text-center">
                  <Button color="phode-green" type="submit">
                    Save
                  </Button>
                </div>
                <div id="messages">{this.state.message}</div>
              </CardBody>
            </Card>
          </form>
          <style jsx>{`
            #front {
              background: url(/img/background.png);
              height: 100vh;
              width: 100%;
            }
            form {
              font-size: 15px;
              width: 450px;
            }
            input {
              color: white !important;
            }
            .md-form label {
              color: white;
            }
            .card {
              background: transparent;
            }
            .card-body {
              background: rgba(255, 255, 255, 0.2);
            }
            form img {
              margin: auto;
              width: 50%;
            }
            #buttons {
              font-size: 12px;
              justify-content: space-between;
            }
            #forgot a {
              text-decoration: underline;
            }
          `}</style>
          <style jsx global>{`
            #root > .row,
            #root footer {
              display: none;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_URL + "translations/login")
      .then((response) => {
        this.setState({
          isLoading: false,
          translations: {
            login: response.data[0].translations,
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export default Login;
