import React from "react";
import { Button, Col, Fa, Card, CardBody, Row } from "mdbreact";
import { Link } from "react-router-dom";
import moment from "moment";
import "./dashboard.css";
import axios from "axios";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import Cookies from "js-cookie";
import Image from "../Image";
const currentLanguage = Cookies.get("lang");

class DashboardElement extends React.Component {
  state = {
    documents: [],
    isLoading: true,
    medias: [],
    news: [],
    users: [],
  };

  render() {
    if (this.state.isLoading === false) {
      let data = [];
      switch (this.props.id) {
        case "documents":
          let documentsArray = this.state.documents.sort(
            (a, b) =>
              moment(b.dateAdded).valueOf() - moment(a.dateAdded).valueOf()
          );
          for (let i = 0; i < 5; i++) {
            if (documentsArray[i]) {
              data.push(
                <li className="d-flex align-items-center mb-2">
                  <div className="elementType mr-3" />
                  <div>
                    <div className="elementTitle">
                      <b>
                        <Link
                          to={
                            "/view/" +
                            this.props.id +
                            "/" +
                            documentsArray[i]._id
                          }
                        >
                          {documentsArray[i].title}
                        </Link>
                      </b>
                    </div>
                    <div className="elementDate">
                      {moment(documentsArray[i].dateAdded).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </li>
              );
            }
          }
          break;
        case "medias":
          let mediasByDate = this.state.medias.sort(
            (a, b) =>
              moment(b.dateAdded).valueOf() - moment(a.dateAdded).valueOf()
          );
          let mediasArray = mediasByDate.sort(function (a, b) {
            if (a.mediaType === "presse") {
              a.mediaType = "a";
            } else if (b.mediaType === "presse") {
              b.mediaType = "a";
            }
            if (a.mediaType < b.mediaType) {
              return -1;
            }
            if (a.mediaType > b.mediaType) {
              return 1;
            }
            return 0;
          });
          for (let i = 0; i < 3; i++) {
            if (mediasArray[i]) {
              data.push(
                <li className="d-flex align-items-center">
                  <Row>
                    <Col sm="8">
                      <div className="elementTitle">{mediasArray[i].title}</div>
                      <div className="elementDate">
                        {moment(mediasArray[i].dateAdded).format("DD/MM/YYYY")}
                      </div>
                    </Col>
                    <Col sm="4" className="elementImg media-col pr-0">
                      {mediasArray[i].mediaType === "image" ||
                      mediasArray[i].mediaType === "a" ? (
                        <React.Fragment>
                          <Link to={mediasArray[i].url}>
                            <Image
                              alt=""
                              src={
                                mediasArray[i].cover
                                  ? mediasArray[i].cover
                                  : "/laboratoires-phode.jpg"
                              }
                            />
                          </Link>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Video
                            loop
                            muted
                            controls={[
                              "PlayPause",
                              "Seek",
                              "Time",
                              "Volume",
                              "Fullscreen",
                            ]}
                            onCanPlayThrough={() => {
                              // Do stuff
                            }}
                          >
                            <source src={mediasArray[i].url} />
                          </Video>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </li>
              );
            }
          }

          break;
        case "news":
          for (let i = 0; i < 3; i++) {
            let newsArray = this.state.news.sort(
              (a, b) =>
                moment(b.dateAdded).valueOf() - moment(a.dateAdded).valueOf()
            );
            if (newsArray[i]) {
              data.push(
                <li className="d-flex align-items-center">
                  <Row>
                    <Col sm="8">
                      <div className="elementTitle">
                        <Link to={"/view/news/" + newsArray[i]._id}>
                          <b>{newsArray[i].title}</b>
                        </Link>
                      </div>
                      <div className="elementDate">
                        {moment(newsArray[i].dateAdded).format("DD/MM/YYYY")}
                      </div>
                      <div className="elementAuthor">
                        Par : {newsArray[i].author}
                      </div>
                    </Col>
                    <Col sm="4" className="pr-0">
                      <div className="elementImg">
                        <Image
                          alt=""
                          src={
                            newsArray[i].url
                              ? newsArray[i].url
                              : "/laboratoires-phode.jpg"
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </li>
              );
            }
          }
          break;
        case "users":
          for (let i = 0; i < 4; i++) {
            if (this.state.users[i]) {
              data.push(
                <li className="d-flex">
                  <Row className="d-flex align-items-center">
                    <Col sm="2">
                      <div className="elementAvatar">
                        {this.state.users[i].avatar ? (
                          <Image alt="" src={this.state.users[i].avatar} />
                        ) : null}
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="elementName">
                        {this.state.users[i].firstName}{" "}
                        {this.state.users[i].LastName}
                      </div>
                      <div className="text-gray">
                        {this.state.users[i].status}
                      </div>
                    </Col>
                  </Row>
                </li>
              );
            }
          }
          break;
        default:
          break;
      }
      return (
        <div id={this.props.id}>
          <Card className="dashboardElement mb-4">
            <h2>{this.props.title}</h2>
            <div />
            <CardBody>
              <ul className="p-0">{data}</ul>
            </CardBody>
            <div id="cardBottom">
              <Link to={"/" + this.props.id} className="addWrapper">
                <Button color="green" className="add">
                  <Fa icon="plus" />
                </Button>
              </Link>
            </div>
          </Card>
          <style jsx>{`
            .rh5v-Overlay_component {
              height: 80px !important;
            }
            .media-col {
              height: 80px;
              margin-bottom: 20px;
              overflow: hidden;
            }
            .mediaImg,
            .mediaImg > div,
            .rh5v-DefaultPlayer_component {
              height: 100%;
            }
            .elementTitle a {
              color: var(--blueDark);
            }
            .elementTitle a:hover {
              color: var(--green);
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "list/news?limit=5&lang=" +
            currentLanguage
        ),
        axios.get(
          process.env.REACT_APP_API_URL +
            "list/documents?limit=5&lang=" +
            currentLanguage +
            "&customised=no"
        ),
        axios.get(
          process.env.REACT_APP_API_URL +
            "list/medias?limit=5&lang=" +
            currentLanguage
        ),
        axios.get(
          process.env.REACT_APP_API_URL +
            "list/users?limit=5&lang=" +
            currentLanguage
        ),
      ])
      .then(
        axios.spread((dataNews, dataDocuments, dataMedias, dataUsers) => {
          this.setState({
            isLoading: false,
            news: [...dataNews.data.elements],
            documents: [...dataDocuments.data.elements],
            medias: [...dataMedias.data.elements],
            users: [...dataUsers.data.elements],
          });
        })
      );
  }
}

export default DashboardElement;
