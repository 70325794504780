import React from "react";
import { Link } from "react-router-dom";
import { Fa, Col, Container, Row } from "mdbreact";
import "./footer.css";
import Logo from "../../img/logo-slogan.png";
import axios from "axios";
import Cookies from "js-cookie";
const moment = require("moment");
const currentLanguage = Cookies.get("lang");

export default class Footer extends React.Component {
  state = {
    isLoading: true,
    translations: "",
  };

  render() {
    const translation = this.state.translations.footer;
    if (this.state.isLoading === false) {
      return (
        <footer>
          <div id="footer1">
            <Container>
              <Row>
                <Col sm="3">
                  <img src={Logo} alt="" />
                </Col>
                <Col sm="3" id="footer-contact">
                  <h3 className="text-uppercase font-weight-bold">
                    {translation.contact[currentLanguage]}
                  </h3>
                  <p>
                    Z.I. Albipôle - 81150 TERSSAC FRANCE
                    <br />
                    T. 33-(0)5 63 77 80 60 <br />
                    F. 33-(0)5 63 77 80 61
                  </p>
                </Col>
                <Col sm="3" id="footer-menu">
                  <h3 className="text-uppercase font-weight-bold">
                    {translation.quick[currentLanguage]}
                  </h3>
                  <ul className="p-0">
                    <li>
                      <Link to={translation.quickLink1.url}>
                        {translation.quickLink1[currentLanguage]}
                      </Link>
                    </li>
                    <li>
                      <Link to={translation.quickLink4.url}>
                        {translation.quickLink4[currentLanguage]}
                      </Link>
                    </li>
                    <li>
                      <Link to={translation.quickLink2.url}>
                        {translation.quickLink2[currentLanguage]}
                      </Link>
                    </li>
                    <li>
                      <Link to={translation.quickLink3.url}>
                        {translation.quickLink3[currentLanguage]}
                      </Link>
                    </li>
                    <li>
                      <Link to={translation.quickLink5.url}>
                        {translation.quickLink5[currentLanguage]}
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col sm="3" id="footer-social">
                  <h3 className="text-uppercase font-weight-bold">
                    {translation.follow[currentLanguage]}
                  </h3>
                  <ul className="d-flex p-0">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/phode/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Fa icon="linkedin-square" />
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
          <div id="footer2">
            <Container className="d-flex justify-content-center">
              <span>
                Copyright {moment().format("YYYY")}
                &nbsp;|&nbsp; Laboratoires Phodé &nbsp; - &nbsp;
              </span>
              <Link to="/mentions-legales">
                {translation.legal[currentLanguage]}
              </Link>
            </Container>
          </div>
          <style jsx>{`
            @media only screen and (max-width: 767px) {
              footer {
                padding-right: 0;
                text-align: center;
              }
              footer img {
                margin-bottom: 15px;
              }
              footer .row > div {
                margin-bottom: 10px;
              }
              #footer-social ul {
                justify-content: center;
              }
            }
          `}</style>
        </footer>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_URL + "translations/footer")
      .then((response) => {
        this.setState({
          isLoading: false,
          translations: {
            footer: response.data[0].translations,
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
