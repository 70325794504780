import moment from "moment";
import React, { Fragment } from "react";
import { Button, Col, Fa, Row, Container, Card, CardBody } from "mdbreact";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import "react-image-lightbox/style.css";
import ListHeader from "./ListHeader";
import ListTabs from "./ListTabs";
import DocumentType from "../core/DocumentsType";
import LinesEllipsis from "react-lines-ellipsis";
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";
import Video from "../Video";
import "react-html5video/dist/styles.css";
import { ClipLoader } from "react-spinners";
import Image from "../Image";
import getSignedUrl from "../../lib/getSignedUrl";
const currentLanguage = Cookies.get("lang");

let selections = [
  "documentsCategoriesSelection",
  "typeSupportSelection",
  "speciesSelection",
  "brandsSelection",
  "searchSelection",
];
let mediasSelections = [
  "mediaTypeSelection",
  "speciesSelection",
  "brandsSelection",
  "searchSelection",
];

let filterBrands = "";
let filterType = "";
let filterCategory = "";
let filterSpecies = "";
let filterTitle = "";
let filterMediaType = "";

class List extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false,
    dataPerPage: 30,
    dataPage: 1,
    documents: [],
    documentsFiltered: [],
    isLoading: true,
    isLoadingText: {
      FR: "Chargement",
      EN: "Loading",
      ES: "Cargando",
    },
    loader: null,
    loaderCode: null,
    medias: [],
    news: [],
    users: [],
    brands: [],
    species: [],
    documentsCategories: [],
    currentLanguage: "",
    typesSupport: [],
    documentsCategoriesSelection: {
      data: [],
    },
    speciesSelection: {
      data: [],
    },
    brandsSelection: {
      data: [],
    },
    mediaType: [],
    mediaTypeSelection: {
      data: [],
    },
    mediaCategoriesSelection: {
      data: [],
    },
    mediasTypes: [],
    mediasTypesSelection: {
      data: [],
    },
    typeSupportSelection: {
      data: [],
    },
    searchSelection: {
      search: [],
    },
    translationsGlobal: {},
    translationsDocuments: {},
    translationsMedias: {},
    translationsNews: {},
    translationsUsers: {},
    videoHeight: "200",
    customisedUrl: null,
    sort: {
      type: "lastName",
      value: "up",
    },
  };

  resetFilters = () => {
    this.setState({
      documentsFiltered: this.state.documents,
      documentsCategoriesSelection: {
        data: [],
      },
      mediaTypeSelection: {
        data: [],
      },
      typeSupportSelection: {
        data: [],
      },
      speciesSelection: {
        data: [],
      },
      brandsSelection: {
        data: [],
      },
      searchSelection: {
        data: [],
      },
    });
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "list/" +
            this.props.type +
            "?lang=" +
            currentLanguage
        ),
      ])
      .then(
        axios.spread((dataList) => {
          this.setState({
            [this.props.type]: [...dataList.data.elements],
            [this.props.type + "Filtered"]: [...dataList.data.elements],
          });
        })
      );
    for (let i = 0; i < selections.length; i++) {
      Cookies.remove(selections[i]);
      Cookies.remove(mediasSelections[i]);
      Cookies.remove(this.props.type + "-" + selections[i]);
      Cookies.remove(this.props.type + "-" + mediasSelections[i]);
      if (this.props.type === "documents") {
        window.location.replace("/documents");
      }
      if (this.props.type === "medias") {
        window.location.replace("/medias");
      }
    }
  };

  filterChange = (filter, data) => {
    if (filter === "search") {
      data = {
        data: data.target.value,
      };
      Cookies.set(this.props.type + "-" + filter + "Selection", data);
    } else {
      data = {
        data: {
          label: data.label,
          value: data.value,
        },
        name: filter,
      };
      Cookies.set(this.props.type + "-" + filter + "Selection", data);
    }

    this.setState(
      {
        [filter + "Selection"]: data,
      },
      () => {
        // Doc Cat
        if (this.state.documentsCategoriesSelection.data.value) {
          filterCategory =
            "&documentCategories=" +
            this.state.documentsCategoriesSelection.data.value;
        }

        // Doc Type
        if (this.state.typeSupportSelection.data.value) {
          filterType =
            "&documentType=" + this.state.typeSupportSelection.data.value;
        }

        // Media Type
        if (this.state.mediaTypeSelection.data.value) {
          filterMediaType =
            "&mediaType=" + this.state.mediaTypeSelection.data.value;
        }

        // Species
        if (this.state.speciesSelection.data.value) {
          filterSpecies = "&species=" + this.state.speciesSelection.data.value;
        }

        // Brands
        if (this.state.brandsSelection.data.value) {
          filterBrands = "&brands=" + this.state.brandsSelection.data.value;
        }

        // Search
        if (this.state.searchSelection.data) {
          filterTitle = "&title=" + this.state.searchSelection.data;
        }

        axios
          .all([
            axios.get(
              process.env.REACT_APP_API_URL +
                "list/" +
                this.props.type +
                "?lang=" +
                currentLanguage +
                filterType +
                filterCategory +
                filterBrands +
                filterSpecies +
                filterTitle +
                filterMediaType
            ),
          ])
          .then(
            axios.spread((dataList) => {
              this.setState({
                [this.props.type]: [...dataList.data.elements],
                [this.props.type + "Filtered"]: [...dataList.data.elements],
              });
            })
          );
      }
    );
  };

  setPager = (page) => {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "list/" +
            this.props.type +
            "?lang=" +
            currentLanguage +
            "&limit=" +
            this.state.dataPerPage
        ),
      ])
      .then(
        axios.spread((dataList) => {
          this.setState({
            [this.props.type]: [...dataList.data.elements],
            [this.props.type + "Filtered"]: [...dataList.data.elements],
          });
        })
      );
  };

  customised = (url, a3, code, id) => {
    this.setState({
      customisedUrl: "",
      loader: "loading-on",
      loaderCode: code,
    });
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "pdf?url=" +
          url +
          "&a3=" +
          a3 +
          "&id=" +
          localStorage.getItem("extranet_phode_id") +
          "&lang=" +
          currentLanguage
      )
      .then((response) => {
        this.setState({
          customisedUrl: response.data.url,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  render() {
    if (this.state.isLoading === false) {
      let data = [];
      const { speciesSelection } = this.state;
      const { brandsSelection } = this.state;
      const { typeSupportSelection } = this.state;
      const { documentsCategoriesSelection } = this.state;

      let createPath = "";
      switch (this.props.type) {
        case "documents":
          createPath = "documents/create";
          break;
        case "medias":
          createPath = "update/medias/create";
          break;
        case "news":
          createPath = "create/news";
          break;
        case "users":
          createPath = "update/users/create";
          break;
        default:
          break;
      }

      /**** DATA ****/
      switch (this.props.type) {
        case "documents":
          let documentsArray = this.state.documentsFiltered.sort(
            (a, b) =>
              moment(b.dateAdded).valueOf() - moment(a.dateAdded).valueOf()
          );
          for (let i = 0; i < documentsArray.length; i++) {
            if (this.props.category === "all") {
              data.push(
                <Col sm="12">
                  <Row className="listItem">
                    <div className="d-flex pt-4 pb-4">
                      <Col sm="1">
                        <DocumentType
                          type={documentsArray[i].url.split(".").pop()}
                        />
                      </Col>
                      <Col sm="4">
                        <div className="font-weight-bold text-body">
                          {documentsArray[i].title}
                        </div>
                        <div className="listEdit">
                          {Cookies.get("role") === "admin" ||
                          Cookies.get("role") === "contributor" ? (
                            <React.Fragment>
                              <Link
                                to={"/documents/edit/" + documentsArray[i]._id}
                                className="text-green"
                                params={{
                                  code: documentsArray[i].code,
                                }}
                              >
                                {
                                  this.state.translationsGlobal.consult[
                                    currentLanguage
                                  ]
                                }
                              </Link>
                              <span> | </span>
                            </React.Fragment>
                          ) : null}
                          <Link
                            to={"/view/documents/" + documentsArray[i]._id}
                            className="text-green"
                            params={{
                              code: documentsArray[i].code,
                            }}
                          >
                            {
                              this.state.translationsGlobal.view[
                                currentLanguage
                              ]
                            }
                          </Link>
                          <span> | </span>
                          {documentsArray[i].customised !== true ? (
                            <span
                              className="cursor-pointer text-green"
                              onClick={() => {
                                async function generateLink() {
                                  const signedUrl = await getSignedUrl(
                                    documentsArray[i].url
                                  );

                                  window.open(signedUrl, "_blank");
                                }
                                generateLink();
                              }}
                            >
                              {
                                this.state.translationsGlobal.download[
                                  currentLanguage
                                ]
                              }
                            </span>
                          ) : (
                            <React.Fragment>
                              <div
                                className="cursor-pointer"
                                onClick={() =>
                                  this.customised(
                                    documentsArray[i].url,
                                    documentsArray[i].a3,
                                    documentsArray[i].code,
                                    documentsArray[i]._id
                                  )
                                }
                                download
                                className="text-green"
                              >
                                {
                                  this.state.translationsGlobal.generate[
                                    currentLanguage
                                  ]
                                }
                                {documentsArray[i].code ===
                                this.state.loaderCode ? (
                                  <React.Fragment>
                                    {this.state.customisedUrl ? (
                                      <a
                                        className="ml-2"
                                        href={this.state.customisedUrl}
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    ) : (
                                      <div
                                        id={documentsArray[i].code}
                                        className={
                                          "loading " + this.state.loader
                                        }
                                      >
                                        <span>
                                          {
                                            this.state.translationsGlobal
                                              .fileGeneration[currentLanguage]
                                          }
                                        </span>
                                        <ClipLoader
                                          className="loader"
                                          sizeUnit={"px"}
                                          size={50}
                                          color={"#4C9314"}
                                          loading={this.state.loading}
                                        />
                                      </div>
                                    )}
                                  </React.Fragment>
                                ) : null}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </Col>
                      <Col sm="1" className="text-gray">
                        {documentsArray[i].code}
                      </Col>
                      <Col sm="3" className="text-body">
                        {documentsArray[i].author}
                      </Col>
                      <Col sm="3" className="text-gray">
                        {moment(documentsArray[i].dateAdded).format(
                          "DD/MM/YYYY"
                        )}
                      </Col>
                    </div>
                  </Row>
                </Col>
              );
            }
          }
          break;
        case "medias":
          let mediasByDate = this.state.medias.sort(
            (a, b) =>
              moment(b.dateAdded).valueOf() - moment(a.dateAdded).valueOf()
          );
          let mediasArray = mediasByDate.sort(function (a, b) {
            if (a.mediaType === "presse") {
              a.mediaType = "a";
            } else if (b.mediaType === "presse") {
              b.mediaType = "a";
            }
            if (a.mediaType < b.mediaType) {
              return -1;
            }
            if (a.mediaType > b.mediaType) {
              return 1;
            }
            return 0;
          });

          for (let i = 0; i < mediasArray.length; i++) {
            if (
              mediasArray[i].url &&
              mediasArray[i].url.split(".").pop() !== "wmv"
            ) {
              data.push(
                <Col sm="4" className="media-col mb-3">
                  <figure className="mediaImg">
                    {mediasArray[i].mediaType !== "video" ? (
                      <React.Fragment>
                        {/* IF PDF */}
                        {mediasArray[i].url.split(".").pop() === "pdf" ? (
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              async function generateLink() {
                                const signedUrl = await getSignedUrl(
                                  mediasArray[i].url
                                );

                                window.open(signedUrl, "_blank");
                              }
                              generateLink();
                            }}
                          >
                            <Image
                              alt=""
                              className="mb-3"
                              src={
                                mediasArray[i].cover
                                  ? mediasArray[i].cover
                                  : "/laboratoires-phode.jpg"
                              }
                              onClick={() => this.setState({ isOpen: true })}
                            />
                          </a>
                        ) : (
                          /* IF NOT AN IMAGE */
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              async function generateLink() {
                                const signedUrl = await getSignedUrl(
                                  mediasArray[i].url
                                );

                                window.open(signedUrl, "_blank");
                              }
                              generateLink();
                            }}
                          >
                            <Image
                              alt=""
                              className="mb-3"
                              src={
                                mediasArray[i].cover
                                  ? mediasArray[i].cover
                                  : "/laboratoires-phode.jpg"
                              }
                            />
                          </span>
                        )}

                        <br />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Video src={mediasArray[i].url} />
                        <br />
                      </React.Fragment>
                    )}
                  </figure>
                  <div className="font-weight-bold mediaTitle">
                    {mediasArray[i].title}
                  </div>
                  <div id="buttons" className="d-flex justify-content-end">
                    {Cookies.get("role") === "admin" ||
                    Cookies.get("role") === "contributor" ? (
                      <Link
                        to={
                          "/update/" +
                          this.props.type +
                          "/" +
                          mediasArray[i]._id
                        }
                      >
                        <Button color="grey">
                          {this.state.translationsNews.edit[currentLanguage]}
                        </Button>
                      </Link>
                    ) : null}
                  </div>
                </Col>
              );
            }
          }
          break;
        case "news":
          let newsArray = this.state.news.sort(
            (a, b) =>
              moment(b.dateAdded).valueOf() - moment(a.dateAdded).valueOf()
          );
          for (let i = 0; i < newsArray.length; i++) {
            data.push(
              <Col sm="4" className="mb-4">
                <Link to={"/view/news/" + newsArray[i]._id}>
                  <Image
                    alt=""
                    src={
                      newsArray[i].url
                        ? newsArray[i].url
                        : "/laboratoires-phode.jpg"
                    }
                    className="mb-3"
                  />
                  <h2 className="mb-2 text-black">{newsArray[i].title}</h2>
                  <hr />
                  <LinesEllipsis maxLine="3">
                    {newsArray[i].rte ? (
                      <p>{JSON.parse(newsArray[i].rte).blocks[0].text}</p>
                    ) : null}
                  </LinesEllipsis>
                  <hr />
                  <div className="text-gray">
                    <i className="fa fa-user mr-2" />{" "}
                    {this.state.translationsNews.author[currentLanguage]} :{" "}
                    {newsArray[i].author}
                  </div>
                  <div className="text-gray">
                    <i className="fa fa-clock-o mr-2" />
                    {this.state.translationsNews.date[currentLanguage]} :{" "}
                    {moment(newsArray[i].dateAdded).format("DD/MM/YYYY")}
                  </div>
                </Link>
                <div id="buttons" className="d-flex justify-content-end">
                  {Cookies.get("role") === "admin" ? (
                    <Link to={"/news/" + newsArray[i]._id + "/edit"}>
                      <Button color="grey">
                        {this.state.translationsNews.edit[currentLanguage]}
                      </Button>
                    </Link>
                  ) : null}
                  <Link to={"/view/news/" + newsArray[i]._id}>
                    <Button color="phode-green">
                      {this.state.translationsNews.readMore[currentLanguage]}
                    </Button>
                  </Link>
                </div>
              </Col>
            );
          }
          break;
        default:
          break;
      }

      let pager = [];
      let dataCount = this.state.dataCount / this.state.dataPerPage;
      for (let i = 1; i <= Math.ceil(dataCount); i++) {
        pager.push(
          <li class="pager-number">
            <button onClick={(e) => this.setPager(i)}>{i}</button>
          </li>
        );
      }

      const sort = (e) => {
        this.setState({
          sort: {
            type: e,
            value: this.state.sort.value === "up" ? "down" : "up",
          },
        });
      };

      return (
        <Fragment>
          <div id="listFilters">
            <div className="container">
              <div className="d-flex">
                {/* DOCUMENTS FILTERS */}
                {this.props.type === "documents" ? (
                  <React.Fragment>
                    <Select
                      className="selectFilter"
                      value={documentsCategoriesSelection.data}
                      onChange={this.filterChange.bind(
                        this,
                        "documentsCategories"
                      )}
                      options={this.state.documentsCategories}
                      placeholder={
                        this.state.translationsDocuments.docCategories[
                          currentLanguage
                        ]
                      }
                    />
                    <Select
                      className="selectFilter"
                      value={typeSupportSelection.data}
                      onChange={this.filterChange.bind(this, "typeSupport")}
                      options={this.state.types}
                      placeholder={
                        this.state.translationsDocuments.type[currentLanguage]
                      }
                    />
                  </React.Fragment>
                ) : null}

                {/* MEDIAS FILTERS */}
                {this.props.type === "medias" ? (
                  <React.Fragment>
                    <Select
                      className="selectFilter"
                      value={this.state.mediaTypeSelection.data}
                      onChange={this.filterChange.bind(this, "mediaType")}
                      options={this.state.mediaType}
                      placeholder={
                        this.state.translationsGlobal.type[currentLanguage]
                      }
                    />
                  </React.Fragment>
                ) : null}

                {/* DOCUMENTS AND MEDIAS FILTERS */}
                {this.props.type === "documents" ||
                this.props.type === "medias" ? (
                  <React.Fragment>
                    <Select
                      className="selectFilter"
                      value={speciesSelection.data}
                      onChange={this.filterChange.bind(this, "species")}
                      options={this.state.species}
                      placeholder={
                        this.state.translationsDocuments.species[
                          currentLanguage
                        ]
                      }
                    />
                    <Select
                      className="selectFilter"
                      value={brandsSelection.data}
                      onChange={this.filterChange.bind(this, "brands")}
                      options={this.state.brands}
                      placeholder={
                        this.state.translationsDocuments.brands[currentLanguage]
                      }
                    />
                    <input
                      type="text"
                      id="search"
                      value={this.state.searchSelection.data}
                      onChange={this.filterChange.bind(this, "search")}
                    />
                  </React.Fragment>
                ) : null}
              </div>
              {this.props.type === "news" ||
              this.props.type === "users" ? null : (
                <div id="reset">
                  <button onClick={this.resetFilters}>
                    {
                      this.state.translationsGlobal.resetFilters[
                        currentLanguage
                      ]
                    }
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="container btn-wrapper">
            {/* REMPLACER PAR /CREATE/TYPE */}
            {Cookies.get("role") === "admin" ? (
              <Link className="create" to={createPath}>
                <Button color="phode-green" create={true}>
                  +
                </Button>
              </Link>
            ) : null}
            {Cookies.get("role") === "contributor" &&
            this.props.type !== "news" ? (
              <Link className="create" to={createPath}>
                <Button color="phode-green" create={true}>
                  +
                </Button>
              </Link>
            ) : null}
          </div>
          <div className="list">
            <Container>
              <Card>
                <ListTabs type={this.props.type} />
                <CardBody className="p-0">
                  <ListHeader type={this.props.type} />
                  {this.props.type === "users" ? (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell
                            className={
                              "pointer " +
                              (this.state.sort.type === "status"
                                ? "bold"
                                : null)
                            }
                            onClick={() => sort("status")}
                          >
                            {
                              this.state.translationsUsers.status[
                                currentLanguage
                              ]
                            }
                            {this.state.sort.type === "status" ? (
                              this.state.sort.value === "up" ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )
                            ) : null}
                          </TableCell>
                          <TableCell
                            className={
                              "pointer " +
                              (this.state.sort.type === "firstName"
                                ? "bold"
                                : null)
                            }
                            onClick={() => sort("firstName")}
                          >
                            {
                              this.state.translationsUsers.firstname[
                                currentLanguage
                              ]
                            }
                            {this.state.sort.type === "firstName" ? (
                              this.state.sort.value === "up" ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )
                            ) : null}
                          </TableCell>
                          <TableCell
                            className={
                              "pointer " +
                              (this.state.sort.type === "lastName"
                                ? "bold"
                                : null)
                            }
                            onClick={() => sort("lastName")}
                          >
                            {
                              this.state.translationsUsers.lastname[
                                currentLanguage
                              ]
                            }
                            {this.state.sort.type === "lastName" ? (
                              this.state.sort.value === "up" ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )
                            ) : null}
                          </TableCell>
                          <TableCell
                            className={
                              "pointer " +
                              (this.state.sort.type === "role" ? "bold" : null)
                            }
                            onClick={() => sort("role")}
                          >
                            {this.state.translationsUsers.role[currentLanguage]}
                            {this.state.sort.type === "role" ? (
                              this.state.sort.value === "up" ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )
                            ) : null}
                          </TableCell>
                          <TableCell>
                            {
                              this.state.translationsUsers.lastConnexion[
                                currentLanguage
                              ]
                            }
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.users
                          .filter((user) => {
                            return user.lastName;
                          })
                          .map((user) => {
                            return Object.assign(user, {
                              role: user.role ? user.role : "none",
                              status: user.password ? "active" : "inactive",
                            });
                          })
                          .sort((a, b) => {
                            return this.state.sort.value === "up"
                              ? a[this.state.sort.type]
                                  .replace(/\s/g, "")
                                  .toLowerCase() >
                                b[this.state.sort.type]
                                  .replace(/\s/g, "")
                                  .toLowerCase()
                                ? 1
                                : -1
                              : b[this.state.sort.type]
                                  .replace(/\s/g, "")
                                  .toLowerCase() >
                                a[this.state.sort.type]
                                  .replace(/\s/g, "")
                                  .toLowerCase()
                              ? 1
                              : -1;
                          })
                          .map((row) => (
                            <TableRow key={row.name}>
                              <TableCell>
                                {Cookies.get("role") === "admin" ? (
                                  <Link
                                    to={"/update/users/" + row._id}
                                    username={row._id}
                                  >
                                    {
                                      this.state.translationsGlobal.modify[
                                        currentLanguage
                                      ]
                                    }
                                  </Link>
                                ) : null}
                              </TableCell>
                              <TableCell>
                                {row.avatar ? (
                                  <Image
                                    alt=""
                                    className="avatar"
                                    src={row.avatar}
                                  />
                                ) : null}
                              </TableCell>
                              <TableCell>
                                {row.password ? (
                                  <Fa icon="check" className="text-green" />
                                ) : (
                                  <Fa icon="close" className="text-red" />
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {row.firstName}
                              </TableCell>
                              <TableCell align="right">
                                {row.lastName}
                              </TableCell>
                              <TableCell align="right">{row.role}</TableCell>
                              <TableCell align="right">
                                {row.password
                                  ? moment(row.lastConnexion).format(
                                      "DD/MM/YYYY"
                                    )
                                  : null}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Row id="listItems">{data}</Row>
                  )}
                </CardBody>
              </Card>
            </Container>
          </div>
          <style jsx>{`
            .avatar {
              border-radius: 100%;
              height: 75px;
              object-fit: cover;
              width: 75px;
            }
            .bold {
              color: var(--green);
              font-weight: bold;
            }
            .pointer {
              cursor: pointer;
            }
            #pager {
              align-items: center;
              justify-content: center;
            }
            .pager-number {
              list-style-type: none;
              list-style-image: none;
              margin: 0 5px;
            }
            .pager-number button {
              background: white;
              border: 1px solid var(--green);
              color: var(--green);
              cursor: pointer;
              padding: 5px;
              border-radius: 100%;
              height: 35px;
              width: 35px;
            }
            .pager-number button:hover,
            .pager-number button.active {
              background: var(--green);
              border: 1px solid var(--green);
              color: white;
            }
            #reset button {
              width: 200px;
              margin-top: 20px;
              background: white;
              border: 0;
              padding: 7px 0;
              color: var(--green);
            }
            .loading {
              color: var(--green);
              display: none;
            }
            .loading-on {
              display: block !important;
            }
            #news #listItems h2 {
              font-size: 20px;
            }
            .news-content {
              height: 150px;
            }
            .media-col {
              padding-bottom: 50px;
            }
            .mediaImg,
            .mediaImg > div {
              height: auto;
            }
            .selectFilter {
              width: 200px;
            }
            .selectFilter > div {
              background: var(--green);
              border: 0;
              border-radis: 5px;
            }
            .selectFilter > div:first-child {
              color: white;
            }
            .selectFilter > div:last-child {
              color: black;
            }
            #listFilters .selectFilter {
              margin-right: 20px;
            }
            #listFilters button {
              border-radius: 5px;
            }
            .list {
              padding: 50px 0;
            }
            .btn-wrapper {
              position: relative;
            }
            .create {
              position: absolute;
              top: -125px;
              right: 0;
              z-index: 1000;
            }
            .create button {
              padding: 0;
              padding: 10px 20px;
              border-radius: 100px;
              font-size: 20px;
            }
            .listTabs {
              background: var(--grayLight);
              padding: 20px 30px;
              text-transform: uppercase;
            }

            #listFilters {
              background: var(--blueDark);
              color: white;
              padding: 20px 0;
            }
            #listHeader {
              border-bottom: 1px solid var(--grayLight);
              padding-bottom: 10px;
            }
            .listItem > div,
            #listHeader {
              padding: 20px;
              width: 100%;
            }
            .listItem > div:hover {
              background: var(--grayLight);
            }
            #listItems i {
              font-size: 20px;
            }
            .elementAvatar {
              border-radius: 100%;
            }
            .mediaImg {
              cursor: pointer;
              margin: 0;
            }
            .mediaImg .rh5v-DefaultPlayer_component {
              height: 250px;
            }
            .mediaImg img {
              height: 200px;
              object-fit: cover;
            }

            @media only screen and (max-width: 767px) {
              #documents,
              #users {
                position: relative;
              }
              #listFilters .selectFilter {
                margin-bottom: 5px;
                width: 100%;
              }
              #listFilters + .btn-wrapper {
                position: absolute;
                top: 0;
              }
              .create {
                top: 10px;
              }
              .container > div {
                flex-direction: column;
              }
              #listHeader {
                display: none;
              }
              .listItem > div {
                display: block !important;
              }
            }
          `}</style>
        </Fragment>
      );
    } else {
      console.log(this.state.sort);
      return (
        <div id="loading">
          <Card>
            <CardBody className="p-4">
              {this.state.isLoadingText[currentLanguage]}...
            </CardBody>
          </Card>
          <style jsx>{`
            #loading {
              height: 400px;
              padding: 50px;
            }
          `}</style>
        </div>
      );
    }
  }
  componentDidMount() {
    console.log("Type : " + this.props.type);
    filterCategory = "";
    filterType = "";
    filterMediaType = "";
    filterSpecies = "";
    filterBrands = "";
    filterTitle = "";

    if (this.props.type === "documents") {
      // Doc Cat
      if (Cookies.get("documents-documentsCategoriesSelection")) {
        filterCategory =
          "&documentCategories=" +
          JSON.parse(Cookies.get("documents-documentsCategoriesSelection")).data
            .value;
        this.setState({
          documentsCategoriesSelection: JSON.parse(
            Cookies.get("documents-documentsCategoriesSelection")
          ),
        });
      }
      // Doc Type
      if (Cookies.get("documents-typeSupportSelection")) {
        filterType =
          "&documentType=" +
          JSON.parse(Cookies.get("documents-typeSupportSelection")).data.value;
        this.setState({
          typeSupportSelection: JSON.parse(
            Cookies.get("documents-typeSupportSelection")
          ),
        });
      }
      if (Cookies.get("documents-speciesSelection")) {
        filterSpecies =
          "&species=" +
          JSON.parse(Cookies.get("documents-speciesSelection")).data.value;
        this.setState({
          speciesSelection: JSON.parse(
            Cookies.get("documents-speciesSelection")
          ),
        });
      }
      if (Cookies.get("documents-brandsSelection")) {
        filterBrands =
          "&brands=" +
          JSON.parse(Cookies.get("documents-brandsSelection")).data.value;
        this.setState({
          brandsSelection: JSON.parse(Cookies.get("documents-brandsSelection")),
        });
      }
      if (Cookies.get("documents-searchSelection")) {
        filterTitle =
          "&title=" + JSON.parse(Cookies.get("documents-searchSelection")).data;
        this.setState({
          searchSelection: JSON.parse(Cookies.get("documents-searchSelection")),
        });
      }
    }

    if (this.props.type === "medias") {
      // Media Type
      if (Cookies.get("medias-mediaTypeSelection")) {
        filterMediaType =
          "&mediaType=" +
          JSON.parse(Cookies.get("medias-mediaTypeSelection")).data.value;
        this.setState({
          mediaTypeSelection: JSON.parse(
            Cookies.get("medias-mediaTypeSelection")
          ),
        });
      }
      if (Cookies.get("medias-speciesSelection")) {
        filterSpecies =
          "&species=" +
          JSON.parse(Cookies.get("medias-speciesSelection")).data.value;
        this.setState({
          speciesSelection: JSON.parse(Cookies.get("medias-speciesSelection")),
        });
      }
      if (Cookies.get("medias-brandsSelection")) {
        filterBrands =
          "&brands=" +
          JSON.parse(Cookies.get("medias-brandsSelection")).data.value;
        this.setState({
          brandsSelection: JSON.parse(Cookies.get("medias-brandsSelection")),
        });
      }
      if (Cookies.get("medias-searchSelection")) {
        filterTitle =
          "&title=" + JSON.parse(Cookies.get("medias-searchSelection")).data;
        this.setState({
          searchSelection: JSON.parse(Cookies.get("medias-searchSelection")),
        });
      }
    }

    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "list/" +
            this.props.type +
            "?lang=" +
            currentLanguage +
            filterType +
            filterCategory +
            filterBrands +
            filterSpecies +
            filterTitle +
            filterMediaType
        ),
        axios.get(process.env.REACT_APP_API_URL + "brands"),
        axios.get(process.env.REACT_APP_API_URL + "types"),
        axios.get(process.env.REACT_APP_API_URL + "species"),
        axios.get(process.env.REACT_APP_API_URL + "documentscategories"),
        axios.get(process.env.REACT_APP_API_URL + "data/mediacategories"),
        axios.get(process.env.REACT_APP_API_URL + "data/mediatypes"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global"),
        axios.get(process.env.REACT_APP_API_URL + "translations/documents"),
        axios.get(process.env.REACT_APP_API_URL + "translations/medias"),
        axios.get(process.env.REACT_APP_API_URL + "translations/news"),
        axios.get(process.env.REACT_APP_API_URL + "translations/users"),
      ])
      .then(
        axios.spread(
          (
            dataList,
            dataBrands,
            dataTypes,
            dataSpecies,
            dataDocumentsCategories,
            dataMediaCategories,
            mediaTypeData,
            dataTrGlobal,
            dataTrDocuments,
            dataTrMedias,
            dataTrNews,
            dataTrUsers
          ) => {
            this.setState({
              isLoading: false,
              [this.props.type]: [...dataList.data.elements],
              [this.props.type + "Filtered"]: [...dataList.data.elements],
              dataCount: dataList.data.number,
              brands: [...dataBrands.data],
              mediaType: [...mediaTypeData.data],
              translationsGlobal: dataTrGlobal.data[0].translations,
              translationsDocuments: dataTrDocuments.data[0].translations,
              translationsMedias: dataTrMedias.data[0].translations,
              translationsNews: dataTrNews.data[0].translations,
              translationsUsers: dataTrUsers.data[0].translations,
            });

            console.log("items : " + dataList.data.elements.length);

            /*
            let rteFinal = "";
            let rte = [];
            // RTE
            if ([this.props.type] === "news") {
              for (let i = 0; i < dataList.data.length; i++) {
                let summaries = [];
                if (dataList.data[i].rte) {
                  rte = JSON.parse(dataList.data[i].rte).blocks;
                  for (let i = 0; i < rte.length; i++) {
                    summaries.push(rte[i].text);
                  }
                }
              }
            }*/

            // medias

            let mediaTypeArray = [];
            const mediaTypePush = new Promise(function (resolve, reject) {
              for (let i = 0; i < mediaTypeData.data.length; i++) {
                mediaTypeArray.push({
                  label: mediaTypeData.data[i][currentLanguage],
                  value: mediaTypeData.data[i].value,
                });
              }
              resolve(mediaTypeArray);
            });
            mediaTypePush
              .then((response) => {
                this.setState({
                  mediaType: response,
                });
              })
              .catch(function (error) {
                console.log(error);
              });

            let mediaCategoriesArray = [];
            const mediaCategoriesPush = new Promise(function (resolve, reject) {
              for (let i = 0; i < dataMediaCategories.data.length; i++) {
                mediaCategoriesArray.push({
                  label: dataMediaCategories.data[i][currentLanguage],
                  value: dataMediaCategories.data[i].value,
                });
              }
              resolve(mediaCategoriesArray);
            });
            mediaCategoriesPush
              .then((response) => {
                this.setState({
                  mediaCategories: response,
                });
              })
              .catch(function (error) {
                console.log(error);
              });

            // Species
            let speciesData = [];
            const speciesPush = new Promise(function (resolve, reject) {
              for (let i = 0; i < dataSpecies.data.length; i++) {
                speciesData.push({
                  label: dataSpecies.data[i][currentLanguage],
                  value: dataSpecies.data[i].value,
                });
              }
              resolve(speciesData);
            });
            speciesPush
              .then((response) => {
                this.setState({
                  species: response,
                });
              })
              .catch(function (error) {
                console.log(error);
              });

            let typesData = [];
            const typesPush = new Promise(function (resolve, reject) {
              for (let i = 0; i < dataTypes.data.length; i++) {
                typesData.push({
                  label: dataTypes.data[i][currentLanguage],
                  value: dataTypes.data[i].value,
                });
              }
              resolve(typesData);
            });
            typesPush
              .then((response) => {
                this.setState({
                  types: response,
                });
              })
              .catch(function (error) {
                console.log(error);
              });

            /* DOCUMENTS CATEGORIES */
            let documentsCategoriesData = [];
            const documentsCategoriesPush = new Promise(function (
              resolve,
              reject
            ) {
              for (let i = 0; i < dataDocumentsCategories.data.length; i++) {
                documentsCategoriesData.push({
                  label: dataDocumentsCategories.data[i][currentLanguage],
                  value: dataDocumentsCategories.data[i].value,
                });
              }
              resolve(documentsCategoriesData);
            });
            documentsCategoriesPush
              .then((response) => {
                this.setState({
                  documentsCategories: response,
                });
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        )
      )
      .catch(function (error) {
        console.log(error);
      });
  }
}

export default List;
