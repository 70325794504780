import moment from "moment";
import Dropzone from "react-dropzone";
import SelectDropdown from "../../components/Select";
import React from "react";
import { Button, Card, CardBody, Col, Container, Input, Row } from "mdbreact";
import { Link } from "react-router-dom";
import MainHeader from "../../components/core/main/MainHeader";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");
const uid2 = require("uid2");

class MediasUpdate extends React.Component {
  state = {
    isLoading: true,
    _id: null,
    content: null,
    dateAdded: moment(),
    title: null,
    mediaCategory: {
      data: [],
    },
    message: "",
    upload: null,
  };

  filterValue = (filter, filterData) => {
    const filtersState = {
      brands: "brands",
      mediatypes: "mediaType",
      species: "species",
    };
    let filterKey = filtersState[filter];
    this.setState({
      [filterKey]: filterData,
    });
  };

  onDrop = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          upload: {
            uploadData: fileAsBinaryString,
            uploadName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  cover = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          cover: {
            coverData: fileAsBinaryString,
            coverName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.setState({
      message: "The media will be available in few minutes...",
    });

    axios
      .post(
        process.env.REACT_APP_API_URL + "update/medias/" + this.state._id,
        {
          ...this.state,
          ...this.state.cover,
          ...this.state.upload,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        window.location.replace("/medias");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  delete = (ev) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "delete/medias/" + this.state._id,
        {
          ...this.state,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // handle success
        window.location.replace("/medias");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    if (this.state.isLoading === false) {
      return (
        <div id="document" className="page">
          <MainHeader title={this.state.title ? this.state.title : null} />
          <div id="pageContent">
            <Container>
              <Row>
                <Col sm="8">
                  <Card>
                    <form onSubmit={this.handleSubmit}>
                      <CardBody>
                        <div>
                          <h2>Cover</h2>
                          <Dropzone
                            onDrop={this.cover.bind(this)}
                            multiple="false"
                          >
                            {
                              this.state.translationsGlobal.fileDrop[
                                currentLanguage
                              ]
                            }
                          </Dropzone>
                          {this.state.cover ? (
                            <p>{this.state.cover.coverName}</p>
                          ) : null}
                        </div>
                        <div id="image" className="mb-3">
                          <h2>
                            {
                              this.state.translationsGlobal.file[
                                currentLanguage
                              ]
                            }
                          </h2>
                          <Dropzone
                            onDrop={this.onDrop.bind(this)}
                            multiple="false"
                          >
                            {
                              this.state.translationsGlobal.fileDrop[
                                currentLanguage
                              ]
                            }
                          </Dropzone>
                          {this.state.upload ? (
                            <p>{this.state.upload.uploadName}</p>
                          ) : null}
                        </div>
                        <h2>Code</h2>
                        {this.state.code}
                        <br />
                        <br />
                        <h2>
                          {this.state.translationsGlobal.title[currentLanguage]}
                        </h2>
                        <Input
                          type="text"
                          value={this.state.title}
                          onChange={this.handleChange}
                          id="title"
                          name="title"
                        />
                        <h2>
                          {
                            this.state.translationsGlobal.language[
                              currentLanguage
                            ]
                          }
                        </h2>
                        <p>(EN/ES/FR)</p>
                        <Input
                          type="textarea"
                          value={this.state.language}
                          onChange={this.handleChange}
                          id="language"
                          name="language"
                        />
                        <div id="buttons">
                          <Button color="phode-green" type="submit">
                            {
                              this.state.translationsGlobal.save[
                                currentLanguage
                              ]
                            }
                          </Button>
                          <Link to="/medias">
                            <Button color="grey">
                              {
                                this.state.translationsGlobal.cancel[
                                  currentLanguage
                                ]
                              }
                            </Button>
                          </Link>
                          {this.props.match.params.id ? (
                            <Button color="red" onClick={this.delete}>
                              {
                                this.state.translationsGlobal.delete[
                                  currentLanguage
                                ]
                              }
                            </Button>
                          ) : null}
                        </div>
                        <div id="messages">{this.state.message}</div>
                      </CardBody>
                    </form>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card className="mb-4">
                    <h3 className="p-4 text-white">Filters*</h3>
                    <CardBody>
                      <br />
                      <h4 className="mb-2">Type</h4>
                      <SelectDropdown
                        type="mediatypes"
                        filterValue={this.filterValue}
                        value={this.state.mediaType}
                      />
                      <br />
                      <h4 className="mb-2">Espèces</h4>
                      <SelectDropdown
                        type="species"
                        filterValue={this.filterValue}
                        value={this.state.species}
                      />
                      <br />
                      <h4 className="mb-2">Marques</h4>
                      <SelectDropdown
                        type="brands"
                        filterValue={this.filterValue}
                        value={this.state.brands}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <style jsx>{`
            h3 {
              background: var(--blue);
              font-size: 20px;
            }
            #image img {
              object-fit: cover;
              height: 300px;
              width: 100%;
            }
            #pageContent {
              padding: 50px 0;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    if (this.props.match.params.id !== "create") {
      axios
        .all([
          axios.get(
            process.env.REACT_APP_API_URL +
              "view/medias/" +
              this.props.match.params.id
          ),
          axios.get(process.env.REACT_APP_API_URL + "translations/global"),
        ])
        .then(
          axios.spread((dataItem, dataTrGlobal) => {
            this.setState({
              isLoading: false,
              ...dataItem.data,
              upload: {
                uploadName: dataItem.data.url,
              },
              cover: {
                coverName: dataItem.data.cover,
              },
              translationsGlobal: dataTrGlobal.data[0].translations,
            });
            if (dataItem.data.authorId) {
              this.setState({
                author: dataItem.data.author,
                authorId: dataItem.data.authorId,
              });
            } else {
              this.setState({
                author:
                  Cookies.get("firstName") + " " + Cookies.get("lastName"),
                authorId: localStorage.getItem("extranet_phode_id"),
              });
            }
          })
        );
    } else {
      axios
        .all([axios.get(process.env.REACT_APP_API_URL + "translations/global")])
        .then(
          axios.spread((dataTrGlobal) => {
            this.setState({
              isLoading: false,
              translationsGlobal: dataTrGlobal.data[0].translations,
              author: Cookies.get("firstName") + " " + Cookies.get("lastName"),
              authorId: localStorage.getItem("extranet_phode_id"),
              code: "DOC" + uid2(6),
              dateAdded: moment(),
            });
          })
        );
    }
  }
}

export default MediasUpdate;
