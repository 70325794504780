import Dropzone from "react-dropzone";
import React, { Fragment } from "react";
import { Button, Card, CardBody, Input } from "mdbreact";
import {
  EditorState,
  Editor,
  convertToRaw,
  RichUtils,
  CompositeDecorator,
} from "draft-js";
import MainHeader from "../components/core/main/MainHeader";
import axios from "axios";
import Cookies from "js-cookie";
import Image from "../components/Image";
const currentLanguage = Cookies.get("lang");
const uid2 = require("uid2");

class Create extends React.Component {
  constructor(props) {
    super(props);
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ]);
    this.state = {
      editorState: EditorState.createEmpty(decorator),
      showURLInput: false,
      urlValue: "",
      author: Cookies.get("firstName") + " " + Cookies.get("lastName"),
      authorId: localStorage.getItem("extranet_phode_id"),
      dateAdded: new Date(),
      selectedFile: null,
      isLoading: true,
      message: "",
      translationsGlobal: null,
      translationsNews: null,
      rte: "",
    };

    this.focus = () => this.refs.editor.focus();
    this.logState = () => {
      const content = this.state.editorState.getCurrentContent();
      console.log(convertToRaw(content));
    };

    this.onURLChange = (e) => this.setState({ urlValue: e.target.value });
    this.onLinkInputKeyDown = this._onLinkInputKeyDown.bind(this);
  }

  onChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    console.log("content state", convertToRaw(contentState));
    this.setState({
      editorState,
      rte: JSON.stringify(convertToRaw(contentState)),
    });
  };

  _promptForLink(e) {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = "";
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      this.setState(
        {
          showURLInput: true,
          urlValue: url,
        },
        () => {
          setTimeout(() => this.refs.url.focus(), 0);
        }
      );
    }
  }

  _confirmLink(e) {
    e.preventDefault();
    const { editorState, urlValue } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    this.setState(
      {
        editorState: RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        ),
        showURLInput: false,
        urlValue: "",
      },
      () => {
        setTimeout(() => this.refs.editor.focus(), 0);
      }
    );
  }

  _onLinkInputKeyDown(e) {
    if (e.which === 13) {
      this._confirmLink(e);
    }
  }

  _removeLink(e) {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  }

  rteInline = (e) => {
    e.preventDefault();
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, e.target.id)
    );
  };
  rteBlock = (e) => {
    e.preventDefault();
    this.onChange(
      RichUtils.toggleBlockType(this.state.editorState, e.target.id)
    );
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onDrop = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          upload: {
            uploadData: fileAsBinaryString,
            uploadName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.setState({
      message: "Saving...",
    });
    axios
      .post(
        process.env.REACT_APP_API_URL + "create/news",
        {
          ...this.state,
          ...this.state.upload,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        window.location.replace("/news");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    if (this.state.isLoading === false) {
      let form = [];
      switch (this.props.match.params.type) {
        case "news":
          form.push(
            <React.Fragment>
              <div className="mb-4">
                <h2>{this.state.translationsGlobal.title[currentLanguage]}</h2>
                <Input
                  value={this.state.title}
                  onChange={this.handleChange}
                  id="title"
                  name="title"
                />
              </div>
              <div className="mb-4">
                <h2 className="mb-4">
                  {this.state.translationsGlobal.image[currentLanguage]}
                </h2>
                <Dropzone onDrop={this.onDrop.bind(this)} multiple="false">
                  {this.state.translationsGlobal.fileDrop[currentLanguage]}
                </Dropzone>
                {this.state.upload ? (
                  <p>{this.state.upload.uploadName}</p>
                ) : null}
                {this.state.selectedFile ? (
                  <Fragment>
                    <Image
                      alt="Preview"
                      src={this.state.selectedFile.src}
                      height="100"
                      width="auto"
                      className="mt-2"
                    />
                  </Fragment>
                ) : null}
              </div>
              <div className="mb-4">
                <h2>Code</h2>
                {this.state.code}
              </div>
              <div className="mb-4">
                <h2>Author</h2>
                {Cookies.get("firstName") + " " + Cookies.get("lastName")}
              </div>
              <div className="mb-4">
                <h2>
                  {this.state.translationsGlobal.content[currentLanguage]}
                </h2>
                <div>
                  <button onClick={this.rteInline.bind(this)} id="ITALIC">
                    Italic
                  </button>
                  <button onClick={this.rteInline.bind(this)} id="BOLD">
                    Bold
                  </button>
                  <button onClick={this.rteInline.bind(this)} id="UNDERLINE">
                    Underline
                  </button>
                  <button onClick={this.rteBlock.bind(this)} id="header-one">
                    H2
                  </button>
                  <button onClick={this.rteBlock.bind(this)} id="header-two">
                    H3
                  </button>
                </div>
                {/*}
                <div style={styles.buttons}>
                  <button onClick={this._promptForLink.bind(this)}>
                    Add Link
                  </button>
                  <button onClick={this._removeLink.bind(this)}>
                    Remove Link
                  </button>
                </div> 
                {urlInput}*/}
                <br />
                <div id="editor">
                  <Editor
                    editorState={this.state.editorState}
                    handleKeyCommand={this.handleKeyCommand}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="mb-4">
                <h2>
                  {this.state.translationsGlobal.language[currentLanguage]}
                </h2>
                <p>EN, FR or ES</p>
                <Input
                  type="textarea"
                  value={this.state.language}
                  onChange={this.handleChange}
                  id="language"
                  name="language"
                />
              </div>
            </React.Fragment>
          );
          break;
        default:
          break;
      }
      return (
        <div id={"create-" + this.props.match.params.type + " page"}>
          <MainHeader
            title={this.state.translationsNews.createNews[currentLanguage]}
          />
          <div id="create-form">
            <div class="container">
              <Card>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    {form}
                    <div id="buttons">
                      <Button color="phode-green" type="submit">
                        {this.state.translationsGlobal.create[currentLanguage]}
                      </Button>
                    </div>
                  </form>
                  <br />
                  <br />
                  <div id="messages">{this.state.message}</div>
                </CardBody>
              </Card>
            </div>
          </div>
          <style jsx>{`
            #editor {
              border-bottom: 1px solid #ced4da;
              padding-bottom: 10px;
            }
            #mainHeader {
              height: 125px;
            }
            h1 {
              margin-top: 30px;
            }
            h2 {
              margin-bottom: 0;
            }
            .md-form {
              margin: 0 !important;
            }
            #create-form {
              padding: 50px 0;
            }
            textarea {
              padding: 0;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    this.setState({
      code: "NEWS" + uid2(8),
      language: currentLanguage,
    });

    axios
      .all([
        axios.get(process.env.REACT_APP_API_URL + "translations/news"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global"),
      ])
      .then(
        axios.spread((dataTrNews, dataTrGlobal) => {
          this.setState({
            isLoading: false,
            translationsNews: dataTrNews.data[0].translations,
            translationsGlobal: dataTrGlobal.data[0].translations,
          });
        })
      )
      .catch(function (error) {
        console.log(error);
      });
  }
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};

const styles = {
  root: {
    fontFamily: "'Georgia', serif",
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: "'Georgia', serif",
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: "1px solid #ccc",
    cursor: "text",
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: "center",
  },
  link: {
    color: "#3b5998",
    textDecoration: "underline",
  },
};

export default Create;
