import moment from "moment";
import Dropzone from "react-dropzone";
import React from "react";
import { Button, Card, CardBody, Container, Input } from "mdbreact";
import {
  EditorState,
  Editor,
  convertToRaw,
  convertFromRaw,
  RichUtils,
} from "draft-js";

import { Link } from "react-router-dom";
import MainHeader from "../../components/core/main/MainHeader";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");

class NewEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      message: "",
      upload: null,
      url: null,
      editorState: EditorState.createEmpty(),
      rte: "",
    };
  }

  onChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    console.log("content state", convertToRaw(contentState));
    this.setState({
      editorState,
      rte: JSON.stringify(convertToRaw(contentState)),
    });
  };

  rteInline = (e) => {
    e.preventDefault();
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, e.target.id)
    );
  };
  rteBlock = (e) => {
    e.preventDefault();
    this.onChange(
      RichUtils.toggleBlockType(this.state.editorState, e.target.id)
    );
  };

  onDrop = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          upload: {
            uploadData: fileAsBinaryString,
            uploadName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.setState({
      message: "Saving...",
    });
    axios
      .post(
        process.env.REACT_APP_API_URL + "update/news/" + this.state._id,
        {
          ...this.state,
          ...this.state.upload,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          this.setState({
            message: response.data.message + " !",
          });
        }, 1000);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  delete = (ev) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "news/delete/" + this.state._id,
        {
          ...this.state,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // handle success
        window.location.replace("/news");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    if (this.state.isLoading === false) {
      console.log(this.state);
      return (
        <div id="document" className="page">
          <MainHeader title={this.state.title} />
          <div id="pageContent">
            <Container>
              <Card>
                <form onSubmit={this.handleSubmit}>
                  <CardBody>
                    <div id="image" className="mb-3">
                      <h2>Image</h2>
                      <Dropzone
                        onDrop={this.onDrop.bind(this)}
                        multiple="false"
                      >
                        {
                          this.state.translationsGlobal.fileDrop[
                            currentLanguage
                          ]
                        }
                      </Dropzone>
                      {this.state.upload ? (
                        <p>{this.state.upload.uploadName}</p>
                      ) : (
                        <p>{this.state.url}</p>
                      )}
                    </div>
                    <h2>Code</h2>
                    {this.state.code}
                    <br />
                    <br />
                    <h2>Author</h2>
                    {this.state.author ? <p>{this.state.author}</p> : null}
                    <br />
                    <br />
                    <h2>Date</h2>
                    {this.state.dateAdded}
                    <br />
                    <br />
                    <h2>Titre</h2>
                    <Input
                      type="text"
                      value={this.state.title}
                      onChange={this.handleChange}
                      id="title"
                      name="title"
                    />
                    <h2>Contenu</h2>
                    <div>
                      <button onClick={this.rteInline.bind(this)} id="ITALIC">
                        Italic
                      </button>
                      <button onClick={this.rteInline.bind(this)} id="BOLD">
                        Bold
                      </button>
                      <button
                        onClick={this.rteInline.bind(this)}
                        id="UNDERLINE"
                      >
                        Underline
                      </button>
                      <button
                        onClick={this.rteBlock.bind(this)}
                        id="header-one"
                      >
                        H2
                      </button>
                      <button
                        onClick={this.rteBlock.bind(this)}
                        id="header-two"
                      >
                        H3
                      </button>
                    </div>
                    <br />
                    <div id="editor">
                      <Editor
                        editorState={this.state.editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this.onChange}
                      />
                    </div>
                    <br />
                    <div id="buttons">
                      <Button color="phode-green" type="submit">
                        Sauvegarder
                      </Button>
                      <Link to="/news">
                        <Button color="grey">Annuler</Button>
                      </Link>
                      <Button color="red" onClick={this.delete}>
                        Supprimer
                      </Button>
                    </div>
                    <div id="messages">{this.state.message}</div>
                  </CardBody>
                </form>
              </Card>
            </Container>
          </div>
          <style jsx>{`
            #editor {
              border-bottom: 1px solid #ced4da;
              padding-bottom: 10px;
            }
            h2 {
              font-size: 20px;
            }
            h3 {
              font-size: 15px;
            }
            #editor {
              border-bottom: 1px solid #ced4da;
            }
            #image img {
              object-fit: cover;
              height: 300px;
              width: 100%;
            }
            #pageContent {
              padding: 50px 0;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    if (this.props.match.params.id !== "create") {
      axios
        .all([
          axios.get(
            process.env.REACT_APP_API_URL + "news/" + this.props.match.params.id
          ),
          axios.get(process.env.REACT_APP_API_URL + "translations/global"),
        ])
        .then(
          axios.spread((dataItem, dataTrGlobal) => {
            this.setState({
              isLoading: false,
              ...dataItem.data,
              translationsGlobal: dataTrGlobal.data[0].translations,
            });
            if (dataItem.data.rte) {
              this.setState({
                editorState: EditorState.createWithContent(
                  convertFromRaw(JSON.parse(dataItem.data.rte))
                ),
              });
            }
            if (dataItem.data.authorId) {
              this.setState({
                author: dataItem.data.author,
                authorId: dataItem.data.authorId,
              });
            } else {
              this.setState({
                author:
                  Cookies.get("firstName") + " " + Cookies.get("lastName"),
                authorId: localStorage.getItem("extranet_phode_id"),
              });
            }

            if (dataItem.data.dateAdded) {
              this.setState({
                dateAdded: dataItem.data.dateAdded,
              });
            } else {
              this.setState({
                dateAdded: moment().format("DD/MM/YYYY"),
              });
            }
          })
        );
    } else {
      axios
        .all([axios.get(process.env.REACT_APP_API_URL + "translations/global")])
        .then(
          axios.spread((dataTrGlobal) => {
            this.setState({
              isLoading: false,
              translationsGlobal: dataTrGlobal.data[0].translations,
              author: Cookies.get("firstName") + " " + Cookies.get("lastName"),
              authorId: localStorage.getItem("extranet_phode_id"),
            });
          })
        );
    }
  }
}

export default NewEdit;
