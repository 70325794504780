import { useState, useEffect } from "react";
import getSignedUrl from "../lib/getSignedUrl";
export default function Image(props) {
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState(null);
  const { src } = props;

  useEffect(() => {
    async function getToken() {
      try {
        const signedUrl = await getSignedUrl(src);

        setFileUrl(signedUrl);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    getToken();
  }, []);

  if (!src || src === null || src === undefined) return;

  if (!loading && fileUrl) {
    return <img {...props} src={fileUrl} alt={props.alt ?? ""} />;
  }
  return;
}
