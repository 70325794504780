import React from "react";
import { Button, Card, CardBody, Container, Input } from "mdbreact";
import MainHeader from "../core/main/MainHeader";
import axios from "axios";
import { Link } from "react-router-dom";

class UserListEdit extends React.Component {
  state = {
    isLoading: true,
    username: "",
    message: "",
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState(updatedState);
  };

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    //Cookies.set("companyLogo", this.state.companyLogo);
    axios
      .post(
        process.env.REACT_APP_API_URL + "users/edit/" + this.props.username,
        {
          ...this.state,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // handle success
        this.setState({
          message: response.data.message + " !",
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    if (this.state.isLoading === false) {
      return (
        <div id="user" className="page">
          <MainHeader
            title={this.state.firstName + " " + this.state.lastName}
          />
          <Container className="ml-0">
            <div id="user-content" className="pb-5">
              <br />
              <br />
              <Card>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <h2 className="mb-3">Informations personnelles</h2>
                    <table>
                      <tr>
                        <td>Nom d'utililisateur</td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.username}
                            onChange={this.handleChange}
                            id="username"
                            name="username"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Prénom</td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                            id="firstName"
                            name="firstName"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Nom</td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            id="lastName"
                            name="lastName"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Profession</td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.profession}
                            onChange={this.handleChange}
                            id="profession"
                            name="profession"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Téléphone fixe</td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.phoneOne}
                            onChange={this.handleChange}
                            id="phoneOne"
                            name="phoneOne"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Téléphone portable</td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.phoneTwo}
                            onChange={this.handleChange}
                            id="phoneTwo"
                            name="phoneTwo"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>E-mail</td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.email}
                            onChange={this.handleChange}
                            id="email"
                            name="email"
                          />
                        </td>
                      </tr>
                    </table>
                    <h2 className="mt-3">Informations personnelles</h2>
                    <p className="mb-3">
                      Les informations suivantes vous permettront de
                      personnaliser certains documents en téléchargement.
                    </p>
                    <table>
                      <tr>
                        <td>Nom de l'entreprise</td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.companyName}
                            onChange={this.handleChange}
                            id="companyName"
                            name="companyName"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Adresse</td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.companyAddress}
                            onChange={this.handleChange}
                            id="companyAddress"
                            name="companyAddress"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Pays</td>
                        <td>
                          {" "}
                          <Input
                            type="text"
                            value={this.state.companyCountry}
                            onChange={this.handleChange}
                            id="companyCountry"
                            name="companyCountry"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Logo<div className="extension">*Format .JPG</div>
                        </td>
                        <td>
                          {" "}
                          <img src={this.state.companyLogo} alt="" />
                        </td>
                      </tr>
                    </table>
                    <div id="buttons" className="mt-5">
                      <Button color="phode-green" type="submit">
                        Sauvegarder
                      </Button>
                      <Link to={"/users/" + this.state.username + "/view"}>
                        <Button color="grey">Annuler</Button>
                      </Link>
                    </div>
                  </form>
                  <div id="messages">{this.state.message}</div>
                </CardBody>
              </Card>
            </div>
          </Container>
          <style jsx>{`
            #messages {
              color: var(--green);
              padding: 20px 0;
            }
            #edit-menu a:after {
              content: "";
              display: block;
              height: 5px;
              width: 100%;
            }
            #edit-menu a.active {
              color: var(--green);
            }
            #edit-menu a.active:after {
              background: var(--green);
            }
            #edit-menu a {
              color: var(--grayText);
            }
            #edit-menu a:after {
              background: var(--grayText);
            }
            h2 {
              font-weight: bold;
            }
            #userMenu {
              border-bottom: 1px solid #f0f0f0;
              padding: 10px 0;
            }
            #userMenu a {
              color: black;
            }
            #userMenu li {
              margin-right: 15px;
            }
            table {
              width: 100%;
            }
            table td {
              border: 1px solid #f0f0f0;
              padding: 10px 20px;
            }
            table > tr > td:first-child {
              width: 20%;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    console.log(this.props.match.params.username);
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "api/users/" +
          this.props.match.params.id +
          "/view"
      )
      .then((response) => {
        this.setState({
          isLoading: false,
          ...response.data[0],
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export default UserListEdit;
