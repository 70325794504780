import { DefaultPlayer } from "react-html5video";
import getSignedUrl from "../lib/getSignedUrl";
import { useEffect, useState } from "react";

export default function Video({ src }) {
  const [signedUrl, setSignedUrl] = useState(null);
  useEffect(() => {
    async function getData() {
      const data = await getSignedUrl(src);
      setSignedUrl(data);
    }

    getData();
  }, []);

  if (!signedUrl) return;

  return (
    <DefaultPlayer
      loop
      muted
      controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
    >
      <source src={signedUrl} />
    </DefaultPlayer>
  );
}
