import React from "react";
import Cookies from "js-cookie";
import { Button } from "mdbreact";
import axios from "axios";
import getSignedUrl from "../../lib/getSignedUrl";

class ListTabs extends React.Component {
  state = {
    export: null,
  };

  export = () => {
    async function setExportUrl(url) {
      const signedUrl = await getSignedUrl(url);

      this.setState({
        export: signedUrl,
      });
    }

    axios
      .get(process.env.REACT_APP_API_URL + "export/" + this.props.type)
      .then((response) => {
        setExportUrl(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    return (
      <div className="listTabs">
        {Cookies.get("role") === "admin" ? (
          <div class="d-flex justify-content-end">
            {this.state.export !== null ? (
              <a
                href={this.state.export}
                className="download"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download file
              </a>
            ) : (
              <Button color="grey" onClick={this.export}>
                Generate Excel file
              </Button>
            )}
          </div>
        ) : (
          <React.Fragment>TOUT</React.Fragment>
        )}
        <style jsx>{`
          .download {
            background: var(--green);
            color: white;
            -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
              0 2px 10px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
              0 2px 10px 0 rgba(0, 0, 0, 0.12);
            padding: 0.84rem 2.14rem;
            font-size: 0.81rem;
          }
          .download:hover {
            color: white;
          }
        `}</style>
      </div>
    );
  }
}

export default ListTabs;
